import React, { useCallback, useEffect, useState } from 'react';
import {
  FieldValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';

import { InputSelect } from '../../InputSelect';
import { CustomOptions, SelectType } from '../protocols';

interface ShowInputSelectProps {
  customOptions?: CustomOptions;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  control: any;
  tiposDeBusca: SelectType[];
  setSelectedTipoDeBusca: (value: any) => void;
  nameBuscaPor: string;
  setValue: UseFormSetValue<FieldValues>;
  resetField?: UseFormResetField<FieldValues>;
  clearCampoBusca?: boolean;
}

/**
  @param clearCampoBusca vai ser responsavel por limpar o campo de BuscaPor
 */

export const ShowInputSelect: React.FC<ShowInputSelectProps> = (props) => {
  const [exibeInput, setExibeInput] = useState<boolean>(false);
  const {
    customOptions,
    tiposDeBusca,
    register,
    isError,
    control,
    setSelectedTipoDeBusca,
    nameBuscaPor,
    setValue,
    resetField,
    clearCampoBusca,
    ...rest
  } = props;

  useEffect(() => {
    renderInputSelectTiposDeBusca();
    if (setValue) setValue('cod_serie', { value: undefined, label: undefined });
    if (resetField) resetField('cod_serie');
  }, []);

  useEffect(() => {
    if (resetField) resetField('cod_serie');
  }, [resetField]);

  /*
    Nestes useEffect's abaixo se atualiza sempre que o customOptions ou o clearCampoBusca for atualizado,
    fazendo assim a verificação novamente dos customOptions.
  */
  useEffect(() => {
    renderInputSelectTiposDeBusca();
  }, [customOptions]);

  /*
    Neste outro useEffect abaixo se atualiza sempre que o clearCampoBusca for atualizado,
    fazendo com que o inputSelect resposavel pelo 'buscaPor:' volte para seu estado inicial.
  */
  useEffect(() => {
    if (clearCampoBusca) {
      setExibeInput(false);
      setSelectedTipoDeBusca({ value: undefined, label: undefined });
      if (setValue) {
        setValue('cod_serie', { value: undefined, label: undefined });
      }
    }
    setTimeout(() => {
      renderInputSelectTiposDeBusca();
    }, 10);
  }, [clearCampoBusca]);

  const renderInputSelectTiposDeBusca = useCallback(() => {
    // Calcula se deve exibir o componente InputSelect ou não
    const showInputSelect: any =
      !customOptions || // Não há customOptions
      !customOptions.buscarPor || // Não há propriedade "buscarPor" em customOptions
      customOptions.buscarPor.length > 1 || // "buscarPor" em customOptions tem mais de uma opção
      (customOptions.hideBuscarPor && customOptions.buscarPor.length > 1); // "hideBuscarPor" é verdadeiro e "buscarPor" em customOptions tem mais de uma opção
    setExibeInput(showInputSelect);
  }, [customOptions]);

  // Retorna o componente InputSelect se exibeInput for verdadeiro, senão retorna null
  return (
    <>
      {exibeInput && (
        <InputSelect
          maxLength={50}
          placeholder="Buscar por"
          name="cod_serie"
          register={register}
          isError={isError}
          control={control}
          options={tiposDeBusca}
          {...rest}
          changeSelected={(selectedValue) => {
            setValue('cod_serie', selectedValue);
            setValue(nameBuscaPor, '');

            setSelectedTipoDeBusca(selectedValue);
          }}
        />
      )}
    </>
  );
};
