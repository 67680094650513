import { TiposDeBusca as TiposDeBuscaAceitos } from '../protocols';

interface TiposDeBusca {
  value: number;
  label: TiposDeBuscaAceitos;
}

export const tiposDeBuscaDefault: TiposDeBusca[] = [
  {
    value: 0,
    label: 'Produto',
  },
  {
    value: 1,
    label: 'Departamento',
  },
  {
    value: 2,
    label: 'Fornecedor',
  },
  {
    value: 3,
    label: 'NCM',
  },
  {
    value: 4,
    label: 'Check-List',
  },
  {
    value: 5,
    label: 'Produto/Embalagem',
  },
  {
    value: 6,
    label: 'Marca',
  },
  {
    value: 7,
    label: 'Alterados',
  },
];
