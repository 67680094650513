import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputDateRange from '~/components/Inputs/InputDateRange';
import MultiSelect from '../../MultiSelect';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { AlteradosEmPorProps } from '../protocols';

export const AlteradosEmPor: React.FC<AlteradosEmPorProps> = ({
  register,
  control,
  setValue,
  name,
  buscaNasLojas,
  selectedTipoDeBusca,
  getProduct,
  setSelected,
  resetCampos,
}) => {
  const [usuarios, setUsuarios] = useState<any>([]);
  const [usuariosSelected, setUsuariosSelected] = useState<any>([]);
  const keyRef = useRef<any>(moment());
  const [dataInfos, setDataInfos] = useState<any>({
    dta_filtro_ini: {
      value: moment().startOf('day'),
      isInvalid: false,
      isRequired: true,
    },
    dta_filtro_fim: {
      value: moment().endOf('day'),
      isInvalid: false,
      isRequired: true,
    },
  });

  useEffect(() => {
    if (setValue) {
      setValue('data_intervalo', {
        dta_filtro_ini: {
          value: moment(moment().startOf('day'), 'DD/MM/YYYY HH:mm A').format(
            'YYYY-MM-DD HH:mm:ss',
          ),
        },
        dta_filtro_fim: {
          value: moment(moment().endOf('day'), 'DD/MM/YYYY HH:mm A').format(
            'YYYY-MM-DD HH:mm:ss',
          ),
        },
      });
    }
  }, []);

  const handleDataIntervalo = (
    ini: string,
    fim: string,
    isInvalid: boolean,
  ) => {
    setDataInfos({
      dta_filtro_ini: {
        isRequired: true,
        value: ini,
        isInvalid,
      },
      dta_filtro_fim: {
        isRequired: true,
        value: fim,
        isInvalid,
      },
    });
    if (setValue) {
      setValue('data_intervalo', {
        dta_filtro_ini: {
          value: ini,
        },
        dta_filtro_fim: {
          value: fim,
        },
      });
    }
  };
  /**
   * fetchData
   * Método que se comunica com a api
   */

  const fetchDataUser = async (): Promise<any> => {
    try {
      if (buscaNasLojas) {
        let idLojas = buscaNasLojas;
        if (!Array.isArray(buscaNasLojas)) {
          idLojas = [buscaNasLojas];
        }
        const { data: dataFields } = await api.post(`/usuariosPorLojas`, {
          idlojas: idLojas,
        });

        const options = dataFields.data.map((dt: any) => {
          return {
            value: dt.cod_usuario,
            label: `${dt.des_usuario}`,
          };
        });
        // Ordenação
        const optionsOrder = options.sort(function (a: any, b: any) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        return {
          ...dataFields,
          data: optionsOrder,
          buscaNasLojas,
        };
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    } catch (error: any) {
      if (error.data?.message) {
        toast.error(error.data.message);
      } else {
        toast.error(String(error));
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
  };

  useEffect(() => {
    const fetchDataAndSetUsuarios = async () => {
      const usuariosData = await fetchDataUser();
      if (usuariosData.success) {
        setUsuarios(usuariosData.data);
      }
    };

    fetchDataAndSetUsuarios();
  }, [buscaNasLojas]);

  const handleClick = useCallback(async () => {
    try {
      const usuariosSelecionados =
        usuariosSelected.length > 0
          ? usuariosSelected.map((item: any) => item.value).toString()
          : null;

      const { data: dataResponse } = await api.get('/busca-produto/data', {
        params: {
          tipoDeBusca: selectedTipoDeBusca.value,
          itemData: {
            dta_ini: dataInfos.dta_filtro_fim.value
              ? moment(
                  dataInfos.dta_filtro_ini.value,
                  'DD/MM/YYYY HH:mm A',
                ).format('YYYY-MM-DD HH:mm:ss')
              : null,
            dta_fim: dataInfos.dta_filtro_fim.value
              ? moment(
                  dataInfos.dta_filtro_fim.value,
                  'DD/MM/YYYY HH:mm A',
                ).format('YYYY-MM-DD HH:mm:ss')
              : null,
            usuarios: usuariosSelecionados,
          },
          lojas: buscaNasLojas,
          buscaItensInativos: false,
        },
      });
      if (dataResponse.success) {
        setSelected(dataResponse.data);
        if (setValue) setValue(name, selectedTipoDeBusca);
        getProduct(dataResponse.data);
      }
    } catch (error: any) {
      if (error?.stack?.includes('Cannot read properties of undefined'))
        return toast.warning('Nenhum produto retornado.');

      if (error.data?.message) {
        toast.error(error.data.message);
      } else {
        if (error.stack) {
          if (
            error.stack.includes(
              "Cannot read properties of undefined (reading 'cod_produto')\n",
            )
          )
            return toast.warning('Nenhum produto encontrado');
        }
        toast.error(String(error));
      }
    }
  }, [
    buscaNasLojas,
    dataInfos.dta_filtro_fim.value,
    dataInfos.dta_filtro_ini.value,
    selectedTipoDeBusca.value,
    usuariosSelected,
  ]);

  useEffect(() => {
    if (resetCampos) {
      keyRef.current = moment();
      setDataInfos({
        dta_filtro_ini: {
          value: moment().startOf('day'),
          isInvalid: false,
          isRequired: true,
        },
        dta_filtro_fim: {
          value: moment().endOf('day'),
          isInvalid: false,
          isRequired: true,
        },
      });
    }
  }, [resetCampos]);

  return (
    <div className="container" style={{ marginBottom: '2px' }}>
      <div className="row align-items-center">
        <div
          className="col-sm-12 col-md-6"
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            padding: '5px',
            boxSizing: 'border-box',
          }}
        >
          <label
            htmlFor="birthdaytime"
            className="form-label"
            style={{
              marginLeft: '5px',
              whiteSpace: 'nowrap',
            }}
          >
            Intervalo:
            <div
              style={{
                flexGrow: 1,
                marginTop: '-29.6px',
              }}
            >
              <InputDateRange
                dataIni={dataInfos.dta_filtro_ini.value}
                dataFim={dataInfos.dta_filtro_fim.value}
                key={keyRef.current}
                labelText=""
                // inLine
                isRequired={dataInfos.dta_filtro_ini.isRequired}
                setInvalid={dataInfos.dta_filtro_ini.isInvalid}
                iniInicializado={false}
                onChange={(ini: string, fim: string, isInvalid = true) => {
                  handleDataIntervalo(ini, fim, isInvalid);
                }}
              />
            </div>
          </label>
        </div>
        <div
          className="col-sm-12 col-md-5"
          style={{
            display: 'flex',
            alignItems: 'start',
            alignContent: 'center',

            padding: '5px',
            boxSizing: 'border-box',
          }}
        >
          <label
            style={{
              // marginLeft: '-70px',
              alignContent: 'center',
            }}
            className="form-label"
            htmlFor="alterado_pelos_usuarios"
          >
            Usuários:
            <div
              style={{
                flexGrow: 1,
                marginTop: '-8px',
              }}
            >
              <MultiSelect
                name="alterado_pelos_usuarios"
                label=""
                placeholder="Selecione..."
                register={register}
                isError={false}
                control={control}
                changeSelected={function (selectedChange: any) {
                  setUsuariosSelected(selectedChange);
                }}
                resetCampos={resetCampos}
                options={usuarios}
                setValue={setValue}
              />
            </div>
          </label>
        </div>
        <div className="col-sm-12 col-md-1">
          <Button
            style={{
              marginBottom: '12px',
            }}
            type="button"
            className="min-button"
            disabled={false}
            onClick={() => {
              handleClick();
            }}
          >
            <AiOutlineSearch color="#fff" />
          </Button>
        </div>
      </div>
    </div>
  );
};
